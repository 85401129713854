<template>
  <div class="t-slider w-100">
    <img :src="'/img/'+img" class="w-100" alt="alt">
  </div>
</template>

<script>
export default {
  name:'TSlider',
  props: ['img'],
}
</script>

<style lang="css" scoped>
</style>
